<template>
    <div class="box my-account shopSuccess">
        <div class="textOk">
            <div class="textOk_icon">
                <i class="el-icon-success"></i>
            </div>
            <div class="textOk_text">
                订单已提交
            </div>
            <div class="textOk_see">
                <el-button type="primary" size="mini" @click="seeOrder">查看订单</el-button>
            </div>
        </div>
        <div class="textLi">
            <ul>
                <li>订单编号：{{query.order_no}}</li>
                <li>支付金额：<span class="text-brand">￥{{query.total_price}}</span></li>
                <li>提示：请尽快按照下面所示信息进行对公转账支付，如已支付，请点击底部按钮【如已转账，点击此处提交转账凭证】按钮，提交转账凭证</li>
            </ul>
        </div>
        <div class="mt30">
            <div class="panel">
                对公转账必填信息
            </div>
            <div class="panel">
                <pre>
                  开户名称：{{$store.getters.accountInfo.value.bank_account_name}}
                  开户银行：{{$store.getters.accountInfo.value.bank_name}}
                  账号：{{$store.getters.accountInfo.value.bank_account_no}}
                </pre>
            </div>
            <div class="alert-info alert">
                <div v-html="$store.getters.accountInfo.value.content"></div>
            </div>
            <div class="outline_btn">
                <el-button type="primary" @click="buyCouponPay">如已转账，点此提交转账凭证</el-button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "shopSuccess",
        data() {
            return {
                query: {},
                msgData: {}
            }
        },
        props: {},
        mixins: [],
        watch: {},
        computed: {},
        created() {
            this.query = this.$route.query
            this.$store.dispatch("member/sysBankAccountDat")
        },
        mounted() {
        },
        methods: {
            sss() {
            },
            seeOrder() {
                this.$router.push({
                    path: "/member/consumptionCoupons"
                })
            },
            buyCouponPay() {
                this.$router.push({
                    path: "/member/buyCouponPay",
                    query: this.query
                })
            }
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
  .my-account {
    background: #ffffff;
    padding: 50px 20px;
  }

  .shopSuccess {
    font-size: 16px;

    .textOk {
      display: flex;
      align-items: center;

      &_icon {
        color: #4caf50;
        font-size: 60px;
      }

      &_text {
        font-size: 26px;
        font-weight: 700;
        color: #54b225;
        margin: 0 10px;
      }
    }
    .textLi{
      font-size: 14px;
      margin-left: 60px;
      color: #999999;
    }
    .text-brand{
      color: #d80000;
    }

  }

</style>
